<template>
  <section class="mt-16">
    <v-breadcrumbs
      large
      :items="[]"
      divider="/"
      class="pl-0"
    ></v-breadcrumbs>


    <v-row>
      <v-col cols="12" md="3">
        <h1 class="primary--text">
          <v-icon color="primary">mdi-view-dashboard</v-icon> <span>{{$t('dashboard.title')}}</span>
        </h1>
      </v-col>
        <!-- DESKTOP -->
      <v-col class="d-none d-sm-block" cols="12" md="9" align="end">
        <v-row justify="end">
          <v-col cols="12" md="auto">
            <span class="font-weight-bold">Link: </span>
          </v-col>
          <v-col cols="12" md="auto">
            <span style="cursor: pointer;" class="font-italic text-decoration-underline" @click="openWebLink(getDashboardLink)">
              {{ getDashboardLink }}
            </span>
          </v-col>
          <v-col cols="12" md="auto"> 
            <v-btn x-small outlined color="primary" fab @click="openQRCode = true">
              <v-icon>mdi-qrcode</v-icon>
            </v-btn>  
          </v-col>
        </v-row>
      </v-col>
        <!-- DESKTOP -->
    </v-row>
      
    <v-row class="mt-4" v-if="!loading">
      <v-col cols="12" md="3">
        <Card :title="$t('dashboard.mine')" :addCircular="true" :subTitle="simple3Rule(fields.total_my_complaints, fields.total_complaints)" :description="fields.total_my_complaints + '/' + fields.total_complaints" icon="mdi-chart-line-stacked"/>
      </v-col>
      <v-col cols="12" md="3">
        <Card :title="$t('dashboard.total')" :subTitle="fields.total_complaints" :description="$t('globals.complaints')" icon="mdi-chart-line-stacked"/>
      </v-col>
      <v-col cols="12" md="3">
        <Card :title="$t('dashboard.new')" :subTitle="fields.total_new_complaints" :description="$t('globals.complaints')" icon="mdi-chart-line-stacked"/>
      </v-col>
      <v-col cols="12" md="3">
        <Card title="Abertas" :subTitle="fields.total_open_cases" :description="$t('globals.complaints')" icon="mdi-chart-line-stacked"/>
      </v-col>
      <v-col cols="12" class="text-h6 primary white--text mt-4">
        {{$t('dashboard.statistics')}}
      </v-col>
      <v-col cols="12" md="6" v-if="fields.category_complaints.values.length > 0">
        <BasicPieChart :values="fields.category_complaints.values"/>
      </v-col>
      <v-col cols="12" md="6" v-if="fields.department_complaints.values.length > 0">
        <BasicPieChart  :values="fields.department_complaints.values"/>
      </v-col>
      <v-col cols="12" class="text-h6 primary white--text mt-4">
        {{$t('dashboard.monthly')}}
      </v-col>
      <v-col cols="12">
        <BasicAreaChart :legend_series="$t('dashboard.monthly')" :categories="fields.month_complaints.months" :new_complaints="fields.month_complaints.values"/>
      </v-col>
    </v-row>

    
    <v-dialog v-if="registerAccounts" v-model="registerAccounts" fullscreen>
      <v-card class="elevation-0">
        <Register :flag="keyOpener" v-if="registerAccounts" @goToLogin="registerAccounts = false" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="openQRCode" width="400">
      <v-card class="elevation-0">
        <v-card-title class="primary white--text">
          QR Code
        </v-card-title>
        <v-card-text class="mt-6">
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="12" md="auto">
                <qrcode-vue :value="'https://' + $store.tenant_company.link" :size="300" level="H"></qrcode-vue>
              </v-col>
            </v-row>            
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </section>
</template>
<script>
import Card from '@/components/ui/Card'
import Dashboard from '@/api/Dashboard.js'
import BasicAreaChart from '@/components/ui/graphs/BasicAreaChart.vue'
import BasicPieChart from '@/components/ui/graphs/BasicPieChart.vue'
import QrcodeVue from 'qrcode.vue'
import Register from '@/views/register_account/Register.vue'

export default{
  name: 'Dashboard',
  components:{
    Card,
    BasicAreaChart,
    BasicPieChart,
    QrcodeVue,
    Register,
  },
  mounted(){
    this.fillBaseData()
    
    if(localStorage['plan'] == 'email-promotion'){
      this.keyOpener = 'email-promotion'
      this.registerAccounts = true
    }
  },
  beforeCreate(){
    document.title = "Ignoto - Dashboard";
  },
  data(){
    return{
      keyOpener: '',
      registerAccounts: false,
      showWelcome: false,
      loading: true,
      complaints: [],
      openQRCode: false,
      fields: null,
      categories: ["05", "06", "07", "08", "09", "10", "11"]
    }
  },
  methods:{
    simple3Rule(mine, total){
      let result = (mine * 100) / total
      return result
    },
    openWebLink(link){
      window.open(link, '_blank')
    },
    async fillBaseData(){
      await Dashboard.getStatistics().then(({data}) => {
          this.fields = data

          console.log(data)

          if(this.fields.first_time_ever == 0){
            let domain = this.$session.get('domain')
            this.$router.push('/' + domain + '/thankyou')
          }
      });

      this.loading = false
    }
  },
  computed:{
    getDashboardLink(){
      let l = process.env.VUE_APP_FRONTOFFICE_URL + this.$session.get('domain')

      return l
    }
  }
}
</script>